import React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import Content from './Content';
import './Main.css';

class Main extends React.Component {
  render() {
    let postNumber = this.props.match.params.postNumber;

    if (!postNumber) {
      postNumber = 63;
    }
    console.log(this.props.sidebarStatus);
    return (
    <div className="main">
      <div className="wrapper">
        <div id="sidebar" className={this.props.sidebarStatus}>
          <Nav vertical className="sidebar">
            <NavItem>  
            <NavLink>
                <Link to="/posts/63">Meadowmist v0.0.1 - December 31, 2020</Link>
              </NavLink> 
            <NavLink>
                <Link to="/posts/62">"Super-Duper-Pre-Alpha" Release... Soon - December 18, 2020</Link>
              </NavLink> 
            <NavLink>
                <Link to="/posts/61">More Music - December 11, 2020</Link>
              </NavLink> 
            <NavLink>
                <Link to="/posts/60">Music - December 4, 2020</Link>
              </NavLink> 
            <NavLink>
                <Link to="/posts/59">Movement Details - November 20, 2020</Link>
              </NavLink> 
            <NavLink>
                <Link to="/posts/58">Weather Control - November 11, 2020</Link>
              </NavLink> 
            <NavLink>
                <Link to="/posts/57">Ugly NPC Code - November 6, 2020</Link>
              </NavLink> 
            <NavLink>
                <Link to="/posts/56">Improved GUI - October 30, 2020</Link>
              </NavLink> 
            <NavLink>
                <Link to="/posts/55">Viewports - October 23, 2020</Link>
              </NavLink>      
              <NavLink>
                <Link to="/posts/54">Particles - October 15, 2020</Link>
              </NavLink>
            <NavLink>
                <Link to="/posts/53">Functional Farming - October 9, 2020</Link>
              </NavLink>
            <NavLink>
                <Link to="/posts/52">Tiling Tools - September 25, 2020</Link>
              </NavLink>
            <NavLink>
                <Link to="/posts/51">Meniere's Disease - September 18, 2020</Link>
              </NavLink>
            <NavLink>
                <Link to="/posts/50">Clearing Land - September 4, 2020</Link>
              </NavLink>
            <NavLink>
                <Link to="/posts/49">Weeds With Friends - August 28, 2020</Link>
              </NavLink>
            <NavLink>
                <Link to="/posts/48">Multiplayer Progress - August 22, 2020</Link>
              </NavLink>
            <NavLink>
                <Link to="/posts/47">Productivity - August 14, 2020</Link>
              </NavLink>
            <NavLink>
                <Link to="/posts/46">Economy - August 7, 2020</Link>
              </NavLink>
            <NavLink>
                <Link to="/posts/45">Title Screen - July 31, 2020</Link>
              </NavLink>
            <NavLink>
                <Link to="/posts/44">Pumpkins - July 17, 2020</Link>
              </NavLink>
            <NavLink>
                <Link to="/posts/43">Forest Edge - July 9, 2020</Link>
              </NavLink>
            <NavLink>
                <Link to="/posts/42">Stroll Through the Hills - July 3, 2020</Link>
              </NavLink>
            <NavLink>
                <Link to="/posts/41">Pebble Friends - June 25, 2020</Link>
              </NavLink>
            <NavLink>
                <Link to="/posts/40">Pencil and Paper - June 19, 2020</Link>
              </NavLink>
            <NavLink>
                <Link to="/posts/39">Meadowmist - June 11, 2020</Link>
              </NavLink>
            <NavLink>
                <Link to="/posts/38">Multiplication - June 5, 2020</Link>
              </NavLink>
            <NavLink>
                <Link to="/posts/37">Maps - May 28, 2020</Link>
              </NavLink>
            <NavLink>
                <Link to="/posts/36">Shop Window - May 21, 2020</Link>
              </NavLink>
            <NavLink>
                <Link to="/posts/35">Text Effects - May 14, 2020</Link>
              </NavLink>
            <NavLink>
                <Link to="/posts/34">Cutscenes - May 7, 2020</Link>
              </NavLink>
            <NavLink>
                <Link to="/posts/33">Butterfly - April 29, 2020</Link>
              </NavLink>
            <NavLink>
                <Link to="/posts/32">Storyboarding - April 23, 2020</Link>
              </NavLink>
            <NavLink>
                <Link to="/posts/31">Progression - April 16, 2020</Link>
              </NavLink>
            <NavLink>
                <Link to="/posts/30">Twenty Pixels - April 8, 2020</Link>
              </NavLink>
            <NavLink>
                <Link to="/posts/29">Portraits - April 1, 2020</Link>
              </NavLink>
            <NavLink>
                <Link to="/posts/28">Projectiles - March 25, 2020</Link>
              </NavLink>
            <NavLink>
                <Link to="/posts/27">Talking - March 19, 2020</Link>
              </NavLink>
            <NavLink>
                <Link to="/posts/26">Rooms - March 12, 2020</Link>
              </NavLink>
            <NavLink>
                <Link to="/posts/25">Creative Inventory - March 4, 2020</Link>
              </NavLink>
            <NavLink>
                <Link to="/posts/24">Flowers - February 27, 2020</Link>
              </NavLink>
            <NavLink>
                <Link to="/posts/23">Cheats - February 19, 2020</Link>
              </NavLink>
            <NavLink>
                <Link to="/posts/22">Refactoring - February 12, 2020</Link>
              </NavLink>
            <NavLink>
                <Link to="/posts/21">Loading Solutions - February 5, 2020</Link>
              </NavLink>
            <NavLink>
                <Link to="/posts/20">Performance - January 29, 2020</Link>
              </NavLink>
            <NavLink>
                <Link to="/posts/19">Chunks - January 23, 2020</Link>
              </NavLink>
            <NavLink>
                <Link to="/posts/18">Big Pictures - January 16, 2020</Link>
              </NavLink>
            <NavLink>
                <Link to="/posts/17">Puppeteering - January 8, 2020</Link>
              </NavLink>
            <NavLink>
                <Link to="/posts/16">The Great Resize - January 2, 2020</Link>
              </NavLink>
            <NavLink>
                <Link to="/posts/15">Indoors - December 18, 2019</Link>
              </NavLink>
            <NavLink>
                <Link to="/posts/14">Inventory - December 11, 2019</Link>
              </NavLink>
            <NavLink>
                <Link to="/posts/13">Seasons - December 4, 2019</Link>
              </NavLink>
            <NavLink>
                <Link to="/posts/12">Pathfinding - November 26, 2019</Link>
              </NavLink>
            <NavLink>
                <Link to="/posts/11">Weeds - November 20, 2019</Link>
              </NavLink>
            <NavLink>
                <Link to="/posts/10">Shaders - November 13, 2019</Link>
              </NavLink>
            <NavLink>
                <Link to="/posts/9">Bird Songs - November 6, 2019</Link>
              </NavLink>
            <NavLink>
                <Link to="/posts/8">Spooky Squares - October 31, 2019</Link>
              </NavLink>
            <NavLink>
                <Link to="/posts/7">VS Red Squares - October 23, 2019</Link>
              </NavLink>
              <NavLink>
                <Link to="/posts/6">Depth Perplexion - October 17, 2019</Link>
              </NavLink>
              <NavLink>
                <Link to="/posts/5">Beds and Trees - October 9, 2019</Link>
              </NavLink>
              <NavLink>
                <Link to="/posts/4">Troublesome Tilemaps - October 2, 2019</Link>
              </NavLink>
            <NavLink>
                <Link to="/posts/3">Distracted By Website - September 25, 2019</Link>
              </NavLink>
              <NavLink>
                <Link to="/posts/2">Browser Game - September 16, 2019</Link>
              </NavLink>
              <NavLink>
                <Link to="/posts/1">Getting Organized - September 9, 2019</Link>
              </NavLink>
            </NavItem>
          </Nav>
        </div>
        <div id="content" className="content">
          <div className="realContent">
            <Content postNumber={postNumber} />
          </div>
          <div className="rss">
            <a href="https://meadowmistgame.com/rss.xml">RSS</a> - <a href="https://meadowmistgame.com/atom.xml">Atom</a>
          </div>
        </div>
      </div>
    </div>
    );
  }
}

export default Main;
