import React from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import './Blog.css';
import ReactMarkdown from 'react-markdown';

//https://www.npmjs.com/package/react-infinite-scroll-component
// API function: get posts in reverse
class Blog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      content: 'Loading...',
      postNumber: 0, // Counts up but really we start from the end
      items: [],
      hasMore: true
    };
  }

  componentDidMount() {
    const {fetchMoreData} = this;
    fetchMoreData();
  }

  fetchMoreData = () => {
    const {postNumber, items} = this.state;
    fetch(`/api/post_range/${postNumber}/${postNumber+4}`)
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({
          items: items.concat(result.items),
          postNumber: postNumber+5,
          hasMore: result.items.length == 5
        });
      }
    )
  };

  render() {
    const {items, hasMore} = this.state;
    const {fetchMoreData} = this;
    return (
      <div className="blogBackground">
        {/*<div className="bBody fullPage"></div> */}
        <div className="blogBody">
          <InfiniteScroll
            dataLength={items.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={<h4>Loading...</h4>}
            endMessage={
              <p style={{ textAlign: 'center' }}>
                <b>No more posts to show</b>
            </p>
            }
          >
          {items.map((i, index) => (
            <div className="entry" key={index}>
              <ReactMarkdown source={i} />
              {i.endsWith("gifs on this blog next time!") &&
              <div>
              <video controls autoplay>
                <source src="https://meadowmistgame.com/api/photos/ReflectingPool.mp4" type="video/mp4"/>
              </video>
              </div>
              }
            </div>
          ))}
          </InfiniteScroll>
        </div>
      </div>
    );
  }
}

export default Blog;