import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Main from '../components/Old/Main';
import Header from '../components/Header/Header';
import NewMain from '../components/NewMain/NewMain';
import Blog from '../components/Blog/Blog'


class Router extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggled: true,
      sidebarStatus: 'active'
    };
  }

  sidebarToggler = () =>
  {
    const {toggled} = this.state;
    var sidebarStatus = toggled ? "" : "active";
    this.setState({toggled: !toggled,
    sidebarStatus: sidebarStatus})
  }

  render() {
    const {sidebarToggler} = this;
    const {sidebarStatus} = this.state;
    return(
  <BrowserRouter>
    <Header sidebarToggler={sidebarToggler}/>
      <Switch>
        <Route path="/blog" render={(props) => <Blog {...props} />} />
        {/* <Route path="/posts/:postNumber" render={(props) => <Main {...props} sidebarStatus={sidebarStatus} />} /> */}
        {/*<Route path="/" render={(props) => <Main {...props} sidebarStatus={sidebarStatus} />} />*/}
        <Route path="/" render={(props) => <NewMain {...props} />} />
      </Switch>
  </BrowserRouter>)
  }
}

export default Router;
