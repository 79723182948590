import React from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button
} from 'reactstrap';
import {
  Link
} from 'react-router-dom';

import './Navbar.css';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      color: this.get_color()
    };
  }

  get_color() {
    var d = new Date(); // current time
    var hours = d.getHours();
    if (hours >= 6 && hours <= 18){
      return "light";
    }
    else{
      return "dark";
    }
  }

  render() {
    return (
      <Navbar className="fixed-top navbar" expand>
        <Nav navbar>
          <NavItem>
            <Link to="/"><Button className="navButton" color="success" size="md" active>Home</Button></Link>
          </NavItem>
          <NavItem>
            <Link to="/blog/"><Button className="navButton" color="success" size="md" active>Blog</Button></Link>
          </NavItem>
        </Nav>
      </Navbar>
    );
  }
}

export default Header;
