import React from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import About from './About/About';

import './NewMain.css';

class NewMain extends React.Component {
  render() {
    return (
      <ParallaxProvider>
        <div className="background">
          <div className="rightBush" />
          <div className="leftBush" />
          <div className="d-flex justify-content-center titleContainer align-items-center">
            {' '}
            {/*<img
              src="https://meadowmistgame.com/api/photos/meadowmist_title_icon.png"
              alt="Meadowmist"
              className="titleImage"
            ></img> */}
          </div>
        </div>
        <div className="bBody">
          <div className="myBorder" />
          <div className="aboutContent">
            <About  />
          </div>
        </div>
      </ParallaxProvider>
    );
  }
}

export default NewMain;
