// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./fonts/m5x7.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".wrapper {\r\n  display: flex;\r\n  width: 100%;\r\n  align-items: stretch;\r\n}\r\n\r\n#sidebar {\r\n  min-width: 250px;\r\n  max-width: 250px;\r\n  min-height: 100vh;\r\n  transition: all 0.3s;\r\n}\r\n\r\n#sidebar.active {\r\n  margin-left: -250px;\r\n}\r\n\r\nbody {\r\n  height: 100vh;\r\n  min-height: 100vh;\r\n  background-color: #bde0ff;\r\n}\r\n\r\n.bBody {\r\n  /*background-image: url(\"https://meadowmistgame.com/api/photos/meadowmist_title_icon.png\");*/\r\n  background-color: #bde0ff;\r\n }\r\n\r\n @font-face {\r\n  font-family: 'myfont';\r\n  src: local('m5x7'), url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('truetype');\r\n}", ""]);
// Exports
module.exports = exports;
