import React from 'react';

import './About.css';
import someFontFamily from '../../../fonts/m5x7.ttf';

class About extends React.Component {
  render() {
    return (
    <div className="aboutBody">
      <p className="lore" style={{ fontFamily: someFontFamily }}>
        Escape and start your garden...
      </p>
      <p className="aboutP">
        <b>Robots have dreams, too.</b> Meadowmist is a top-down RPG/farming game that follows a robot 
        on a mission to start their own garden. The robot adventures to a mysteriously
        abandoned planet where they can cultivate to their metallic heart's content.
      </p>
    </div>
    );
  }
}

export default About;