import React from 'react';
import './Content.css';
import ReactMarkdown from 'react-markdown';

class Content extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      content: 'Loading...'
    };
  }

  componentDidMount() {
    const {getPost} = this;
    getPost();
  }

  componentDidUpdate(prevProps) {
    if (this.props.postNumber !== prevProps.postNumber) {
      const {getPost} = this;
      getPost();
    }
  }

  getPost = () => {
    const {postNumber} = this.props;
    fetch(`/api/posts/post${postNumber}.md`)
      .then(result => result.text())
      .then(result => {
        this.setState({
          content: result
        });
      });
  }


  render() {
    return (
      <div>
        <div>
          <ReactMarkdown source={this.state.content} />
        </div>
      </div>
    );
  }
}

export default Content;
